<template>
  <b-tabs v-model="active" :position="data.Position" expanded :animated="false" :class="isMobile ? 'ipost-tabs-mobile' : 'ipost-tabs'">
    <b-tab-item v-for="(d, i) in data.Data" :key="i">
      <template #header>
        <div class="flex flex-col items-center relative w-full pb-3">
          <span v-if="isSm ? i === active : true" class="ipost-tab-title">{{d.Title}}</span>
          <div class="ipost-tab-icon">
            <div class="relative">
              <b-icon v-if="d.Icon" :icon="d.Icon"></b-icon>
              <div v-if="d.Image" class="bg-white" style="border-radius: 100px;">
                <img :src="d.Image" :class="i === active ? 'bg-white' : 'bg-white opacity-70'" class="ml-0 mr-0" style="border-radius: 100px;" />
                <b-tag v-if="d.Badge" type="is-warning" rounded>{{d.Badge}}</b-tag>
              </div>
            </div>
          </div>
        </div>
      </template>
      <slot name="tabcontent" v-bind="d"><cms v-if="active === i" :data="d.Data"></cms></slot>
    </b-tab-item>
  </b-tabs>
</template>

<script>
export default {
  components: {
    Cms: () => import('../cms')
  },
  props: {
    data: Object
  },
  data () {
    const path = this.$route.path
    const active = (this.data.Data || []).findIndex(d => d.To === path)
    return {
      active: active === -1 ? 0 : active
    }
  },
  watch: {
    active (v) {
      if (this.data.Data && this.data.Data[v] && this.data.Data[v].To) {
        this.$router.replace(this.data.Data[v].To)
      }
    }
  }
}
</script>

<style>
  .b-tabs nav ul li {
    flex: 1
  }
  .b-tabs nav {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 1
  }
</style>

<style scoped>
  .b-tabs >>> .tab-content {
    padding: 0
  }
</style>
